// Form.js
//TODO add ant design loading icon
import React, { useState, useEffect } from 'react';
import { Radio, Button, Input, Spin } from 'antd';

function Form() {
  const [name, setName] = useState(''); // State for name input
  const [email, setEmail] = useState(''); // State for email input
  const [emailSent, setEmailSent] = useState(false); // State to track email sent status
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (emailSent) {
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_REDIRECT;
      }, 3000); // Optional delay of 3 seconds before redirecting
    }
  }, [emailSent]);


  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start spinner
    const response = await fetch(`${process.env.REACT_APP_API}/send-email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, email }), // Send name and email to the server
    });
    setLoading(false); // Stop spinner

    if (response.ok) {
      setEmailSent(true);
    } else {
      alert("Failed to send email.");
    }
  };
  

  return (
    <div style={{ fontFamily: 'Montserrat, sans-serif', color: '#333' }}>
      {/* Header */}
      <header
        style={{
          padding: '2em',
          color: 'white',
          textAlign: 'center',
          backgroundImage: `url("/background.jpg")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <h1 style={{ 
          fontSize: '2.5em', 
          fontWeight: '700', 
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)' 
        }}>
          Membership Application
        </h1>
        <p style={{ fontSize: '1.2em', marginTop: '0.5em', fontWeight: '400' }}>
          Join our journey towards unity and everlasting life.
        </p>
      </header>

      {/* Form Section */}
      <div style={{ padding: '2em', textAlign: 'center', maxWidth: '600px', margin: '0 auto', backgroundColor: '#F9F9F9' }}>
        {emailSent ? (
          <p style={{ fontSize: '1.1em', color: '#333' }}>Application succesfully submited. Confirmation has been sent to your email. Thank you.</p>
        ) : (
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1.5em' }}>
            
            {/* Personal Details */}
            <label style={{ fontSize: '1.1em', fontWeight: '500', textAlign: 'left' }}>
              Full Name:
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                style={{
                  marginTop: '0.5em',
                  padding: '0.5em',
                  fontSize: '1em',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  width: '100%',
                }}
              />
            </label>

            <label style={{ fontSize: '1.1em', fontWeight: '500', textAlign: 'left' }}>
              Birthplace:
              <Input
                type="text"
                style={{
                  marginTop: '0.5em',
                  padding: '0.5em',
                  fontSize: '1em',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  width: '100%',
                }}
              />
            </label>

            <label style={{ fontSize: '1.1em', fontWeight: '500', textAlign: 'left' }}>
              Date of Birth:
              <Input
                type="date"
                style={{
                  marginTop: '0.5em',
                  padding: '0.5em',
                  fontSize: '1em',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  width: '100%',
                }}
              />
            </label>

            {/* Individual Survey Questions */}
            <div style={{ textAlign: 'left' }}>
              <p style={{ fontSize: '1.1em', fontWeight: '500' }}>How important is everlasting life to you?</p>
              <Radio.Group style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                <Radio value="Extremely important">Extremely important</Radio>
                <Radio value="Somewhat important">Somewhat important</Radio>
                <Radio value="I'm still exploring">I'm still exploring</Radio>
                <Radio value="Not important to me">Not important to me</Radio>
              </Radio.Group>
            </div>

            <div style={{ textAlign: 'left' }}>
              <p style={{ fontSize: '1.1em', fontWeight: '500' }}>Do you consider yourself a devoted follower of our mission?</p>
              <Radio.Group style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                <Radio value="Yes, fully devoted">Yes, fully devoted</Radio>
                <Radio value="On my way">On my way</Radio>
                <Radio value="Interested, but not yet committed">Interested, but not yet committed</Radio>
                <Radio value="I’m new here">I’m new here</Radio>
              </Radio.Group>
            </div>

            <div style={{ textAlign: 'left' }}>
              <p style={{ fontSize: '1.1em', fontWeight: '500' }}>How would you describe your physical health?</p>
              <Radio.Group style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                <Radio value="Excellent">Excellent</Radio>
                <Radio value="Good">Good</Radio>
                <Radio value="Fair">Fair</Radio>
                <Radio value="Poor">Poor</Radio>
              </Radio.Group>
            </div>

            <div style={{ textAlign: 'left' }}>
              <p style={{ fontSize: '1.1em', fontWeight: '500' }}>Do you have any history of blood pressure issues?</p>
              <Radio.Group style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
                <Radio value="Prefer not to say">Prefer not to say</Radio>
              </Radio.Group>
            </div>

            <div style={{ textAlign: 'left' }}>
              <p style={{ fontSize: '1.1em', fontWeight: '500' }}>Have you experienced any recent changes in your health?</p>
              <Radio.Group style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
                <Radio value="Not sure">Not sure</Radio>
              </Radio.Group>
            </div>

            <div style={{ textAlign: 'left' }}>
              <p style={{ fontSize: '1.1em', fontWeight: '500' }}>How would you describe your current level of energy?</p>
              <Radio.Group style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                <Radio value="High energy">High energy</Radio>
                <Radio value="Moderate energy">Moderate energy</Radio>
                <Radio value="Low energy">Low energy</Radio>
                <Radio value="Very low energy">Very low energy</Radio>
              </Radio.Group>
            </div>

            <div style={{ textAlign: 'left' }}>
              <p style={{ fontSize: '1.1em', fontWeight: '500' }}>What is your age group?</p>
              <Radio.Group style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                <Radio value="18-25">18-25</Radio>
                <Radio value="26-35">26-35</Radio>
                <Radio value="36-45">36-45</Radio>
                <Radio value="46-60">46-60</Radio>
                <Radio value="61 and above">61 and above</Radio>
              </Radio.Group>
            </div>

            <div style={{ textAlign: 'left' }}>
              <p style={{ fontSize: '1.1em', fontWeight: '500' }}>Are you currently on any medication?</p>
              <Radio.Group style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
                <Radio value="Prefer not to say">Prefer not to say</Radio>
              </Radio.Group>
            </div>
            
            {/* Email Input */}
            <label style={{ fontSize: '1.1em', fontWeight: '500', textAlign: 'left' }}>
              Enter Email:
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{
                  marginTop: '0.5em',
                  padding: '0.5em',
                  fontSize: '1em',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  width: '100%',
                }}
              />
            </label>

            {/* Submit Button */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1em' }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#698DAD',
                  borderColor: '#698DAD',
                  color: 'white',
                  fontSize: '1.2em',
                  padding: '0.5em 1em',
                }}
                disabled={loading}
              >
                Submit
              </Button>
              {loading && <Spin size="small" />}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default Form;
