// Home.js
import React from 'react';
import { Row, Col, Image, Button } from 'antd';
import { useNavigate } from 'react-router-dom';


function Home() {
    const navigate = useNavigate();

    return (
        <div style={{ fontFamily: 'Montserrat, sans-serif', color: '#333' }}>
            {/* Header with Background Image */}
            <header
                style={{
                    padding: '2em',
                    color: 'white',
                    textAlign: 'center',
                    backgroundImage: `url("/background.jpg")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <h1 style={{ 
                    fontSize: '2.5em', 
                    fontWeight: '700', 
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)' // Drop shadow for better readability
                }}>
                    New Dawn Church of Christ
                </h1>
                <p style={{ fontSize: '1.2em', marginTop: '0.5em', fontWeight: '400' }}>A Community United in Pursuit of Everlasting Life</p>
            </header>

            {/* Minister Image and Name */}
            <section style={{ textAlign: 'center', padding: '2em 0', backgroundColor: '#F9F9F9' }}>
                <h1 style={{ fontSize: '2.5em', fontWeight: '700' }}>Welcome to Our Community</h1>
                <Image
                    src="/minister.jpeg"
                    alt="Minister Sasha Volkov"
                    width={400}
                    style={{ borderRadius: '50%', marginBottom: '1em' }}
                />
                <h2 style={{ fontSize: '1.5em', fontWeight: '700' }}>Minister Sasha Volkov</h2>
                <p style={{ fontSize: '1.1em', maxWidth: '600px', margin: '0 auto' }}>
                    Under the guidance of Minister Sasha Volkov, we come together to embark on a path toward a new dawn, one illuminated by unity, devotion, and the pursuit of eternal life. Join us as we explore the mysteries of faith and unlock the potential of life beyond the ordinary.
                </p>

                {/* JOIN US Button */}
                <Button
                    type="primary"
                    size="large"
                    style={{
                        marginTop: '1.5em',
                        fontSize: '1.5em',
                        padding: '0.5em 2em',
                        backgroundColor: '#698DAD',
                        borderColor: '#698DAD'
                    }}
                    onClick={() => navigate('/form')}
                >
                    JOIN US
                </Button>
            </section>

            {/* Mission Section */}
            <section style={{ padding: '2em', textAlign: 'center' }}>
                <h2 style={{ fontSize: '2em', marginBottom: '0.5em', fontWeight: '700' }}>Our Mission</h2>
                <p style={{ fontSize: '1.1em', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto', fontWeight: '400' }}>
                    At New Dawn Church of Christ, we believe that true faith transcends the ordinary bounds of human existence. Our mission is to bring people together under a shared purpose—reaching beyond life as we know it. We devote ourselves fully to the pursuit of lasting, unwavering life, guided by love, trust, and the strength of our community.
                </p>
            </section>

            {/* Values Section */}
            <section style={{ padding: '2em', backgroundColor: '#F9F9F9' }}>
                <div style={{ textAlign: 'center' }}>
                    <h2 style={{ fontSize: '2em', marginBottom: '0.5em', fontWeight: '700' }}>Our Values</h2>
                    <p style={{ fontSize: '1.1em', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto', fontWeight: '400' }}>
                        Devotion, Unity, and Sacrifice. We are committed to supporting one another on our spiritual journeys, recognizing that each of us plays a role in building something far greater than ourselves. Together, we move toward a vision of everlasting life—a future where all who walk in faith are rewarded.
                    </p>
                </div>
            </section>

            {/* Events Section */}
            <section style={{ padding: '2em', textAlign: 'center' }}>
                <h2 style={{ fontSize: '2em', marginBottom: '0.5em', fontWeight: '700' }}>Upcoming Events</h2>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '1.5em', flexWrap: 'wrap', maxWidth: '1000px', margin: '0 auto' }}>
                    <div style={{ width: '250px', textAlign: 'left' }}>
                        <h3 style={{ fontSize: '1.2em', marginTop: '0.5em', fontWeight: '700' }}>Service to the Community</h3>
                        <p style={{ fontWeight: '400' }}>Together, we serve with open hearts, working towards a brighter future for all.</p>
                    </div>
                    <div style={{ width: '250px', textAlign: 'left' }}>
                        <h3 style={{ fontSize: '1.2em', marginTop: '0.5em', fontWeight: '700' }}>Youth Fellowship</h3>
                        <p style={{ fontWeight: '400' }}>A gathering of our youth, exploring the teachings that guide us toward a fulfilled life.</p>
                    </div>
                    <div style={{ width: '250px', textAlign: 'left' }}>
                        <h3 style={{ fontSize: '1.2em', marginTop: '0.5em', fontWeight: '700' }}>Sunday Worship</h3>
                        <p style={{ fontWeight: '400' }}>Join us for our weekly service as we come together in pursuit of faith, unity, and life everlasting.</p>
                    </div>
                </div>
            </section>

            {/* Photo Gallery Section */}
            <section style={{ padding: '2em', textAlign: 'center', backgroundColor: '#f5f5f5' }}>
                <h2 style={{ fontSize: '2em', marginBottom: '0.5em', fontWeight: '700' }}>Our Journey in Pictures</h2>
                <Row gutter={[16, 16]} justify="center">
                    <Image.PreviewGroup>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Image
                                src="/photo1.jpg"
                                alt="Church Event 1"
                                style={{ borderRadius: '5px' }}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Image
                                src="/photo2.jpg"
                                alt="Church Event 2"
                                style={{ borderRadius: '5px' }}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Image
                                src="/photo3.png"
                                alt="Church Event 3"
                                style={{ borderRadius: '5px' }}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Image
                                src="/photo4.png"
                                alt="Church Event 5"
                                style={{ borderRadius: '5px' }}
                            />
                        </Col>
                    </Image.PreviewGroup>
                </Row>
            </section>

            {/* Footer */}
            <footer style={{ padding: '1.5em', backgroundColor: '#698DAD', color: 'white', textAlign: 'center', marginTop: '2em' }}>
                <p style={{ fontSize: '1em', fontWeight: '400' }}>New Dawn Church of Christ | Camden, Maine</p>
                <p style={{ fontSize: '0.9em', marginTop: '0.5em', fontWeight: '400' }}>© {new Date().getFullYear()} All rights reserved.</p>
            </footer>
        </div>
    );
}

export default Home;
